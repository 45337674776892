import { jsPDF } from "jspdf";
import { serviceLevels } from "./carsTypes";
import "jspdf-autotable";
import moment from "moment";

async function getImageDimensions(url) {
  try {
    // Fetch the image as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    // Create an ImageBitmap from the Blob
    const imageBitmap = await createImageBitmap(blob);

    // Extract dimensions
    const width = imageBitmap.width;
    const height = imageBitmap.height;

    // Clean up the ImageBitmap
    imageBitmap.close();

    return { width, height };
  } catch (error) {
    console.error("Error fetching image dimensions:", error);
    return null;
  }
}

function getDesiredDimensions(
  naturalWidth,
  naturalHeight,
  maxWidth,
  maxHeight
) {
  // Calculate the aspect ratio of the image
  const aspectRatio = naturalWidth / naturalHeight;

  // Initialize desired dimensions
  let desiredWidth = naturalWidth;
  let desiredHeight = naturalHeight;

  // Check if the image width exceeds the max width
  if (desiredWidth > maxWidth) {
    desiredWidth = maxWidth;
    desiredHeight = maxWidth / aspectRatio;
  }

  // Check if the resized height exceeds the max height
  if (desiredHeight > maxHeight) {
    desiredHeight = maxHeight;
    desiredWidth = maxHeight * aspectRatio;
  }

  return {
    width: desiredWidth,
    height: desiredHeight,
  };
}

export const PaymentInvoiceGenerate = async (
  invoiceDetail,
  downloadStatus = true,
  orgImage,
  type = "arraybuffer"
) => {
  const { orgData, driver, invoiceNo, from, to, bookings, total, vatAmount } =
    invoiceDetail;

  const options = {
    format: "a3",
    orientation: "portrait",
    unit: "mm",
    format: [297, 420], // A3 dimensions in mm
  };
  const pdf = new jsPDF(options);

  // Invoice header
  pdf.setFont("Courier");

  //  Set Image
  if (orgImage) {
    const { width: naturalWidth, height: naturalHeight } =
      await getImageDimensions(orgImage);
    const { width: desiredWidth, height: desiredHeigth } = getDesiredDimensions(
      naturalWidth,
      naturalHeight,
      naturalWidth,
      40
    );
    pdf.addImage(orgImage, "png", 40, 20, desiredWidth, desiredHeigth); // Adjust image dimensions and position as needed
  }

  // Invoice number and date
  pdf.setFontSize(12);
  pdf.setTextColor(103, 102, 102);
  pdf.text(
    `Invoice# ${invoiceNo || ""}`,
    pdf.internal.pageSize.getWidth() - 40,
    33,
    { align: "right" }
  );
  pdf.text(
    `Issue date: ${moment().format("YYYY-MM-DD")}`,
    pdf.internal.pageSize.getWidth() - 40,
    40,
    { align: "right" }
  );

  // Invoice From
  pdf.setFontSize(14);
  pdf.setFont(undefined, "bold");
  pdf.setTextColor(10, 1, 0);
  pdf.text("INVOICE FROM", 40, 75);

  pdf.setFontSize(13);
  pdf.setFont(undefined, "bold");
  pdf.text(driver.companyName || "", 40, 82);

  pdf.setFontSize(12);
  pdf.setTextColor(102, 102, 102);
  pdf.setFont(undefined, "normal");
  pdf.text(driver.companyAddress || "", 40, 90);
  pdf.text(
    `${driver.companyPostCode || ""} ${driver.companyCity || ""}`,
    40,
    95
  );

  // Invoice To
  pdf.setFontSize(14);
  pdf.setFont(undefined, "bold");
  pdf.setTextColor(10, 1, 0);
  pdf.text("INVOICE TO", pdf.internal.pageSize.getWidth() - 40, 75, {
    align: "right",
  });

  pdf.setFontSize(13);
  pdf.setFont(undefined, "bold");
  pdf.text(
    orgData.companyName || "",
    pdf.internal.pageSize.getWidth() - 40,
    83,
    { align: "right" }
  );
  pdf.setFontSize(12);
  pdf.setTextColor(102, 102, 102);
  pdf.setFont(undefined, "normal");
  pdf.text(
    orgData.companyAddress || "",
    pdf.internal.pageSize.getWidth() - 40,
    90,
    { align: "right" }
  );

  pdf.text(
    `${orgData?.companyPostCode || ""}${
      orgData?.companyCity ? " " + orgData?.companyCity : ""
    }`,
    pdf.internal.pageSize.getWidth() - 40,
    95,
    {
      align: "right",
    }
  );

  pdf.text(
    `${orgData.activeCountry || ""}`,
    pdf.internal.pageSize.getWidth() - 40,
    100,
    {
      align: "right",
    }
  );

  pdf.text(
    `VAT No: ${orgData.companyVATNumber || "n/a"}`,
    pdf.internal.pageSize.getWidth() - 40,
    110,
    { align: "right" }
  );
  pdf.text(
    `KVK No: ${orgData.companyRegistrationNumber || "n/a"}`,
    pdf.internal.pageSize.getWidth() - 40,
    117,
    { align: "right" }
  );

  pdf.setTextColor(102, 102, 102);
  pdf.setFontSize(12);
  pdf.setFont(undefined, "normal");

  pdf.text(`From Date: ${moment(from).format("YYYY-MM-DD") || ""}`, 40, 110);
  pdf.text(`To Date: ${moment(to).format("YYYY-MM-DD") || ""}`, 40, 117);

  //   Table
  // Invoice table
  const tableData = bookings.map((booking, index) => [
    (index + 1).toString(),
    booking.date || "",
    booking.guestName || "",
    booking.bookingReference || booking.refId || "",
    booking.carType ? serviceLevels[booking.carType] : "n/a",
    `€${(booking.finalPrice ? booking.finalPrice.toFixed(2) : 0).toLocaleString(
      "en-US",
      { minimumFractionDigits: 2 }
    )}`,
    `${orgData.vatPercentage || "0"} %`,
  ]);

  pdf.autoTable({
    head: [["#", "Date", "Name", "Booking", "Service Level", "Amount", "VAT"]],
    body: tableData,
    startY: 135,
    headStyles: {
      fontSize: 13,
      fontStyle: "bold",
      fillColor: [255, 255, 255],
      textColor: [10, 1, 0], // Black
      lineWidth: 0,
      lineColor: "gray",
    },
    margin: { left: 40, right: 40 },
    bodyStyles: {
      lineWidth: 0.1,
      lineColor: [169, 169, 169], // Light gray
      textColor: [12, 16, 12],
    },
    theme: "plain",
    didParseCell: function (data) {
      data.cell.styles.font = "courier";
      data.cell.styles.fontSize = 12;
    },
  });

  pdf.setTextColor(78, 53, 73);
  pdf.setFont(undefined, "bold");
  pdf.text(
    `Subtotal (excl. VAT):`,
    pdf.internal.pageSize.getWidth() - 70,
    pdf.autoTable.previous.finalY + 13,
    { align: "right" }
  );
  pdf.setFont(undefined, "normal");
  pdf.text(
    `€ ${(total - vatAmount).toFixed(2)}`,
    pdf.internal.pageSize.getWidth() - 43,
    pdf.autoTable.previous.finalY + 13,
    { align: "right" }
  );

  pdf.setFont(undefined, "bold");
  pdf.text(
    `${orgData.vatPercentage || "0"}% VAT:`,
    pdf.internal.pageSize.getWidth() - 70,
    pdf.autoTable.previous.finalY + 23,
    { align: "right" }
  );
  pdf.setFont(undefined, "normal");
  pdf.text(
    `€ ${vatAmount.toFixed(2)}`,
    pdf.internal.pageSize.getWidth() - 43,
    pdf.autoTable.previous.finalY + 23,
    { align: "right" }
  );

  pdf.line(
    150,
    pdf.autoTable.previous.finalY + 27,
    256,
    pdf.autoTable.previous.finalY + 27
  );

  pdf.setFont(undefined, "bold");
  pdf.text(
    `Total:`,
    pdf.internal.pageSize.getWidth() - 85,
    pdf.autoTable.previous.finalY + 33
  );
  pdf.setFont(undefined, "bold");
  pdf.text(
    `€ ${total.toFixed(2)}`,
    pdf.internal.pageSize.getWidth() - 43,
    pdf.autoTable.previous.finalY + 33,
    { align: "right" }
  );
  pdf.setFont(undefined, "normal");

  //   footer

  const footerText = `Invoice issued on behalf of ${
    driver.companyName || ""
  } by ${orgData.companyName || ""} Through Mobio Technologies B.v.
    The recipient will receive €${total
      .toFixed(2)
      .replace(".", ",")} stating the invoice ${invoiceNo || ""} at ${
    driver.IBAN || ""
  }.`;

  pdf.setFontSize(11);
  const totalPages = pdf.internal.getNumberOfPages();
  const lastPageHeight = pdf.internal.pageSize.getHeight() - 10;

  pdf.setLineWidth(0.5); // Adjust line width as needed
  pdf.line(
    40,
    lastPageHeight - 5,
    pdf.internal.pageSize.getWidth() - 30,
    lastPageHeight - 5
  );

  pdf.setPage(totalPages);
  pdf.text(footerText, pdf.internal.pageSize.getWidth() / 2, lastPageHeight, {
    align: "center",
  });

  //   save
  if (downloadStatus) {
    pdf.save(`${invoiceNo}.pdf`);
  } else {
    let getPdfData = null;
    if (type == "arraybuffer") {
      getPdfData = pdf.output("arraybuffer");
    } else if (type == "blob") {
      const pdfBlob = pdf.output("blob");
      getPdfData = URL.createObjectURL(pdfBlob);
      // return url;
    }
    return getPdfData;
  }
};
